import React from "react";

const Logo = (props) => {
  return (
    <img
      src="/images/mainlogo.svg"
      alt="Logo"
      {...props}
      style={{ userSelect: "none" }}
      draggable="false"
    />
  );
};

export default Logo;
