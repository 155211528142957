import {
  AppBar,
  Toolbar,
  makeStyles,
  Button,
  IconButton,
  Drawer,
  Box,
  Container,
  Typography,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Logo from "./../../component/Logo";
import { FaHome, FaWallet } from "react-icons/fa";
import { useContext } from "react";
import { AuthContext } from "src/context/Auth";
import { BiLogOutCircle } from "react-icons/bi";
import ConfirmationDialog from "src/component/ConfirmationDialog";
import Cookies from "js-cookie";
const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: " 5px 0px",
  },
  "@keyframes scrollLeft": {
    "0%": {
      transform: "translateX(100%)",
    },
    "100%": {
      transform: "translateX(-100%)",
    },
  },
  maindrawer: {
    height: "100%",
    background: "#0c0731",
    width: "260px",
  },
  logoDrawer: {
    width: "130px", //100px
    marginBottom: "15px",
  },
  drawerContainer: {
    padding: "20px 0px 20px 20px",
    height: "100%",
    background: "#0d0d0d",
    color: "#fff",
    width: "260px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  drawericon: {
    color: "#000",
    marginLeft: "0px !important",
    fontSize: "25px",
  },
  mainHeader: {
    justifyContent: "space-between",
    padding: "0px",
    // [theme.breakpoints.up("md")]: {
    //   width: "76%",
    // },
    "& .centerLogoBox": {
      width: "66%",
      [theme.breakpoints.down("sm")]: {
        width: "60%",
        top: "0",
        left: "53%",
        display: "flex",
        alignItems: "center",
        height: "100%",
        "& p": {
          lineHeight: "18px",
        },
      },
    },
    "& h2": {
      [theme.breakpoints.down("md")]: {
        fontSize: "28px !important",
      },

      [theme.breakpoints.down("sm")]: {
        fontSize: "20px !important",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "11px !important",
      },
    },
  },
}));

export default function Header() {
  const { toolbar, drawerContainer, drawericon, logoDrawer, mainHeader } =
    useStyles();
  const history = useHistory();
  let buttonType = window.location.search.split("?")[1];
  const auth = useContext(AuthContext);

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const handleDrawerOpen = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: true }));
  const handleDrawerClose = () =>
    setState((prevState) => ({ ...prevState, drawerOpen: false }));
  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 1280
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };
    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar className={toolbar}>
        <Box display="flex" justifyContent="flex-start" alignItems="flex-start">
          <Box>{femmecubatorLogo}</Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Box className="centerLogoBox">
            {(window.location.pathname == "/play" || // eslint-disable-line
              window.location.pathname == "/play-game") && ( // eslint-disable-line
              <Box textAlign="center">
                <Typography variant="h6">
                  The First ‘Wheel Of Fortune’ Determined By Sentiment and
                  Informed By Artificial Intelligence!
                </Typography>
              </Box>
            )}
            {window.location.pathname == "/fortune-pool" && ( // eslint-disable-line
              <Box textAlign="center">
                {/* {auth.nextLive != "00:00" && buttonType == "live" ? (
                  <Typography variant="h4" style={{ color: "#fff" }}>
                    {auth.count
                      ? "Place Your Bets. The Play Video Button Below Will be Active in: "
                      : "Next ‘Multiplayer For Money’ Game Will Be Live In: "}
                    <span
                      style={{
                        fontFamily: "Arial",
                        color: "#fcff00",
                      }}
                    >
                      {auth.nextLive ? auth.nextLive : ""}
                    </span>
                  </Typography>
                ) : (
                  ""
                )} */}
              </Box>
            )}
          </Box>
          {getDrawerChoices()}
        </Box>
      </Toolbar>
    );
  };

  const [open, setOpen] = useState(false);
  const confirmationHandler = () => {
    history.push("/login");
    window.localStorage.removeItem("token");
    auth.userLogIn(false, null);
    Cookies.remove("jwt");
  };

  const displayMobile = () => {
    //mobile
    return (
      <Toolbar className={mainHeader}>
        <Box className="centerLogoBox">
          {(window.location.pathname == "/play" || // eslint-disable-line
            window.location.pathname == "/play-game") && ( // eslint-disable-line
            <Box textAlign="center">
              <Typography variant="body1">
                The First ‘Wheel Of Fortune’ Determined By Sentiment and
                Informed By Artificial Intelligence!
              </Typography>
            </Box>
          )}
        </Box>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div
            className={drawerContainer}
            style={{ alignItems: "center", paddingRight: "16px" }}
          >
            <img className={logoDrawer} src="images/mainlogo.svg" alt="" />
            {getDrawerChoices()}
          </div>
        </Drawer>

        <Box display="flex" justifyContent="space-between">
          <Box>{femmecubatorLogo}</Box>
        </Box>
        {/* {window.location.pathname !== "/fortune-pool" && ( */}
        <Box display="flex" alignItems="center">
          <IconButton
            className={drawericon}
            {...{
              edge: "start",
              color: "inherit",
              "aria-label": "menu",
              "aria-haspopup": "true",
              onClick: handleDrawerOpen,
            }}
          >
            <MenuIcon
              width="60px"
              height="60px"
              style={{ color: "#57bf8f", fontSize: "26px" }}
            />
          </IconButton>
        </Box>
        {/* )} */}
      </Toolbar>
    );
  };

  //mobile end
  const getDrawerChoices = () => {
    return (
      <>
        {state.mobileView && (
          <>
            {buttonType !== "money" && (
              <IconButton
                onClick={() => {
                  handleDrawerClose();
                  if (auth.userLoggedIn) {
                    history.push({
                      pathname: "/fortune-pool",
                      search: "money",
                    });
                    window.sessionStorage.removeItem("guest");
                  } else {
                    history.push("/login");
                  }
                }}
                style={
                  !mobileView
                    ? {
                        border: "1px solid #fff",
                        padding: "10px",
                        marginRight: "10px",
                      }
                    : {
                        border: "1px solid #fff",
                        padding: "7px 12px",
                        borderRadius: "10px",
                        marginTop: "11px",
                        width: "100%",
                      }
                }
              >
                &nbsp;&nbsp;
                <Typography variant="h6"> Play for Money </Typography>
              </IconButton>
            )}
            {buttonType !== "guest" && (
              <IconButton
                onClick={() => {
                  handleDrawerClose();
                  if (auth.userInLivemode) {
                    auth?.setIsRoutes(false);
                  }
                  window.sessionStorage.setItem("guest", "LogesIn");
                  auth.setisGuest(true);
                  history.push(`/fortune-pool?guest`);
                }}
                style={
                  !mobileView
                    ? {
                        border: "1px solid #fff",
                        padding: "10px",
                        marginRight: "10px",
                      }
                    : {
                        border: "1px solid #fff",
                        padding: "7px 12px",
                        borderRadius: "10px",
                        marginTop: "11px",
                        width: "100%",
                      }
                }
              >
                &nbsp;&nbsp;
                <Typography variant="h6"> Play for Fun </Typography>
              </IconButton>
            )}
          </>
        )}
        {!auth.userLoggedIn ? (
          <>
            <Button
              variant="contained"
              className="TopbarButton"
              to="/login"
              color="primary"
              component={Link}
              size="small"
              style={{
                whiteSpace: "pre",
                borderRadius: "5px",
                width: `${mobileView && "100%"}`,
              }}
            >
              Log In
            </Button>

            <Button
              variant="contained"
              className="TopbarButton"
              to="/register"
              color="primary"
              size="small"
              component={Link}
              style={{
                whiteSpace: "pre",
                borderRadius: "5px",
                background:
                  window.location.pathname == "/" ? " !important" : "", // eslint-disable-line

                color: window.location.pathname == "/" ? "#fff !important" : "", // eslint-disable-line
                width: `${mobileView && "100%"}`,
              }}
            >
              Register
            </Button>
          </>
        ) : (
          <>
            {auth.userData.userType == "ADMIN" && ( // eslint-disable-line
              <Button
                onClick={() => history.push("/quiz-management")}
                style={
                  !mobileView
                    ? {
                        border: "1px solid #fff",
                        padding: "10px",
                        marginRight: "10px",
                        borderRadius: "50px",
                        background: "#49e0a8",
                      }
                    : {
                        border: "1px solid #fff",
                        padding: "7px 12px",
                        borderRadius: "10px",
                        marginTop: "11px",
                        width: "100%",
                        background: "#49e0a8",
                      }
                }
              >
                <Typography variant="h6">Admin </Typography>
              </Button>
            )}
            {window.location.pathname == "/user-profile" ? (
              <IconButton
                onClick={() => history.push("/")}
                style={
                  !mobileView
                    ? {
                        border: "1px solid #fff",
                        padding: "10px",
                        marginRight: "10px",
                      }
                    : {
                        border: "1px solid #fff",
                        padding: "7px 12px",
                        borderRadius: "10px",
                        marginTop: "11px",
                        width: "100%",
                      }
                }
              >
                <FaHome
                  style={{ color: "#fff", height: "20px", width: "20px" }}
                />{" "}
                {mobileView && (
                  <>
                    &nbsp;&nbsp;<Typography variant="h6">Home </Typography>
                  </>
                )}
              </IconButton>
            ) : (
              <IconButton
                onClick={() => history.push("/user-profile")}
                style={
                  !mobileView
                    ? {
                        border: "1px solid #fff",
                        padding: "10px",
                        marginRight: "10px",
                      }
                    : {
                        border: "1px solid #fff",
                        padding: "7px 12px",
                        borderRadius: "10px",
                        marginTop: "11px",
                        width: "100%",
                      }
                }
              >
                <FaWallet
                  style={{ color: "#fff", height: "20px", width: "20px" }}
                />{" "}
                {mobileView && (
                  <>
                    &nbsp;&nbsp;
                    <Typography variant="h6">Wallet </Typography>
                  </>
                )}
              </IconButton>
            )}

            <IconButton
              // onClick={() => history.push("/user-profile")}
              onClick={() => setOpen(true)}
              style={
                !mobileView
                  ? {
                      border: "1px solid #fff",
                      padding: "10px",
                      marginRight: "10px",
                    }
                  : {
                      border: "1px solid #fff",
                      padding: "7px 12px",
                      borderRadius: "10px",
                      width: "100%",
                      marginTop: "11px",
                    }
              }
            >
              <BiLogOutCircle
                style={{ color: "#fff", height: "20px", width: "20px" }}
              />
              {mobileView && (
                <>
                  &nbsp;&nbsp;<Typography variant="h6">Logout</Typography>
                </>
              )}
            </IconButton>
          </>
        )}
        {open && (
          <ConfirmationDialog
            open={open}
            handleClose={() => setOpen(false)}
            title={"LOGOUT"}
            desc="Are you sure want to logout ?"
            logoutHandler={confirmationHandler}
            style={{ color: "#fff" }}
            // confirmationHandler={confirmationHandler}
          />
        )}
      </>
    );
  };
  const femmecubatorLogo = (
    <Box>
      <Link to="/">
        <Logo className="logoImg" />
      </Link>
    </Box>
  );
  return (
    <>
      <AppBar
        position={history.location.pathname !== "/" ? "absolute" : "absolute"}
        elevation={0}
        style={{
          backgroundColor: "transparent",
          border: "none",
          zIndex: "9999999",
        }}
        className="hedaerTopbar"
      >
        <Container maxWidth="fixed">
          {mobileView ? displayMobile() : displayDesktop()}
        </Container>
      </AppBar>
    </>
  );
}
