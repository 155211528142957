import React from "react";
import { makeStyles, Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: "center",
    backgroundColor: "#0d0d0d",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    position: "fixed",
    width: "100%",
    zIndex: 2000,
  },
  loader: {
    width: "250px",
    maxWidth: "100%",
  },
  progressBar: {
    height: "3px",
  },
}));

export default function PageLoading() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Box align="center">
        <img
          className={classes.loader}
          src="/images/mainlogo.svg"
          alt="loader"
        />
      </Box>
    </div>
  );
}
